import {
  HeaderStyledNavItem,
  HeaderStyledNavItemLink,
} from './AudiHeaderNavItemStyles';
import { default as React, useCallback } from 'react';
import {
  createResetActiveItemAction,
  createSetActiveItemAction,
} from '../../actions/header-actions';
import { getClassNamesForLinks, getLinkTarget } from '../../services';
import { AudiHeaderNavItemProps } from '../../interfaces/header-components.interfaces';
import AudiHeaderSubNav from '../AudiHeaderSubNav/AudiHeaderSubNav';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { LayerContentHTML } from '@volkswagen-onehub/audi-feature-app-layer/dist/utils';
import { useStateSwitchTimeOutHook } from '../../services/hooks';

const AudiHeaderNavItem: React.FC<AudiHeaderNavItemProps> = ({
  audiMarketContextService,
  headerDispatch,
  headerNavigationItemsAmount,
  headerState,
  headerStateService,
  index,
  isSubNavOpen,
  item,
  layerManager,
  useFootnoteReferenceServiceTextParserHook,
  useOneLayer,
}) => {
  const { stateSwitch: addClosingAnimation } = useStateSwitchTimeOutHook(440);

  const dangerousHTML = useFootnoteReferenceServiceTextParserHook(
    item.Link.Text.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      'illegalscript',
    ),
  );

  const toggleSubNavigation: (event) => void = (event) => {
    event.preventDefault();
    onMenuItemClick(event, item.Link);
    if (index !== headerState.activeItem.index) {
      headerDispatch(
        createSetActiveItemAction({
          anchor: event.currentTarget,
          headerStateService,
          index,
          showSearch: false,
        }),
      );
    } else {
      headerDispatch(createResetActiveItemAction(headerStateService));
      addClosingAnimation();
    }
  };

  const onMenuItemClick = useCallback(
    (event, listItem): void => {
      if (
        listItem.Target === '_layer' &&
        listItem.Type === 'default' &&
        layerManager &&
        useOneLayer
      ) {
        event.preventDefault();
        event.stopPropagation();

        layerManager.openFocusLayer(
          () => (
            <LayerContentHTML
              audiMarketContextService={audiMarketContextService}
              url={listItem.Url}
            />
          ),
          {},
          {
            size: FocusLayerSizeV2.A,
            userCloseable: true,
          },
        );
      }
    },
    [audiMarketContextService, layerManager, useOneLayer],
  );

  const onMainMenuClick = useCallback(
    (event) => {
      onMenuItemClick(event, item.Link);
    },
    [item.Link, onMenuItemClick],
  );

  const closeSubNavigation: (event) => void = (event) => {
    event.preventDefault();
    headerDispatch(createResetActiveItemAction(headerStateService));
  };

  return (
    <React.Fragment>
      {item.SubNavigation?.length > 0 ? (
        <HeaderStyledNavItem
          headerNavigationItemsAmount={headerNavigationItemsAmount}
        >
          <HeaderStyledNavItemLink
            className={getClassNamesForLinks(item.Link, useOneLayer)}
            data-testid="one-header-nav-item-link"
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            href={item.Link.Url}
            isSubNavOpen={isSubNavOpen}
            onClick={toggleSubNavigation}
            target={getLinkTarget(item.Link)}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: dangerousHTML,
              }}
            />
            <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 16.9l5.5-5.4L10 6.1"
                fill="none"
                stroke="currentColor"
              />
            </svg>
          </HeaderStyledNavItemLink>
          <AudiHeaderSubNav
            closeSubNavigation={closeSubNavigation}
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            isSubNavOpen={headerState.activeItem.index === index}
            onItemClick={onMenuItemClick}
            subNavItem={item}
            useFootnoteReferenceServiceTextParserHook={
              useFootnoteReferenceServiceTextParserHook
            }
            useOneLayer={useOneLayer}
          />
        </HeaderStyledNavItem>
      ) : (
        <HeaderStyledNavItem
          headerNavigationItemsAmount={headerNavigationItemsAmount}
        >
          <HeaderStyledNavItemLink
            className={getClassNamesForLinks(item.Link, useOneLayer)}
            data-testid="one-header-nav-item-link"
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            href={item.Link.Url}
            isSubNavOpen={headerState.activeItem.index === index}
            onClick={onMainMenuClick}
            target={item.Link.Target}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: dangerousHTML,
              }}
            />
          </HeaderStyledNavItemLink>
        </HeaderStyledNavItem>
      )}
    </React.Fragment>
  );
};

export default AudiHeaderNavItem;
