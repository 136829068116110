import * as React from 'react';

import {
  AudiFootnoteReferenceServiceInterfaceV3,
  AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
} from '@volkswagen-onehub/audi-footnote-reference-service';
import { AudiPlatformProvider, audiLightTheme } from '@audi/audi-ui-react';
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from '@feature-hub/core';
import {
  SearchManagerServiceV3,
  defineSearchManagerService,
} from '@volkswagen-onehub/audi-search-manager-service';
import {
  fetchHeaderConfig,
  initialHeaderConfig,
} from './services/navigation-service';

import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import AudiHeaderDataManager from './components/AudiHeader/AudiHeaderDataManager';
import { AudiHeaderStateServiceInterfaceV1 } from '@volkswagen-onehub/audi-header-state-service';
import { AudiMarketContextServiceV2 } from '@volkswagen-onehub/audi-market-context-service';
import HeaderResponse from './interfaces/header-response.interfaces';
import { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { ReactFeatureApp } from '@feature-hub/react';
import { SearchEventType } from '@volkswagen-onehub/audi-search-manager-service/dist/enums';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';

/**
 * - `navigationServiceUrl` is an URL defining the header resource. A corresponding JSON schema is shown in the README.
 *
 * **Example:**
 *
 * ```jsx
 * <feature-app
 *   id="<unique-identifier>"
 *   config='{
 *       "navigationServiceUrl": "<api-base-url>/navigation/navigation.json"
 *     }'
 *   css='[{"href": "../audi-feature-app-header.css"}]'
 *   src="audi-feature-app-header.umd.js"
 * ></feature-app>
 * ```
 */
export type AudiHeaderConfig = {
  navigationServiceUrl: string;
};

interface Dependencies extends FeatureServices {
  readonly 'audi-header-state-service': AudiHeaderStateServiceInterfaceV1;
  readonly 'audi-market-context-service': AudiMarketContextServiceV2;
  readonly 'locale-service': LocaleServiceV1;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:logger': Logger | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1 | undefined;
  readonly 'search-manager-service': SearchManagerServiceV3;
  readonly 'layer-manager': LayerManagerV25;
}

type FeatureAppDefinitionType = FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies,
  AudiHeaderConfig
>;

declare global {
  interface Window {
    SETUPS: {
      get(string): string;
    };
  }
}

const searchInputFeatureAppUrl =
  'https://featureapps.audi.com/audi-feature-app-search-input/3/audi-feature-app-search-input.umd.js';
const searchResultsFeatureAppUrl =
  'https://featureapps.audi.com/audi-feature-app-search-results/3/audi-feature-app-search-results.umd.js';
const loginFeatureAppUrl =
  'https://featureapps.audi.com/audi-feature-app-user-menu/5/app.js';
const miniCartFeatureAppBaseUrl =
  'https://featureapps.audi.com/audi-feature-app-oneshop-frontend-mini-cart/4.0.3';
const miniCartFeatureAppSrc = 'mini-cart.js';

const featureAppDefinition: FeatureAppDefinitionType = {
  // eslint-disable-next-line max-statements
  create: ({ featureServices, config, featureAppId }) => {
    const headerConfigDataUrl: string = config.navigationServiceUrl;
    const serializedStateManager =
      featureServices['s2:serialized-state-manager'];
    const asyncSsrManager = featureServices['s2:async-ssr-manager'];
    const headerStateService = featureServices['audi-header-state-service'];
    const searchManagerService = featureServices['search-manager-service'];
    const layerManager = featureServices['layer-manager'];
    const audiMarketContextService =
      featureServices['audi-market-context-service'];

    const referenceServiceScopeManager = featureServices[
      'audi-footnote-reference-service'
    ] as AudiFootnoteRefernceServiceScopeManagerInterfaceV3;

    const referenceServiceManager: AudiFootnoteReferenceServiceInterfaceV3 = referenceServiceScopeManager
      ? referenceServiceScopeManager.getDefaultScopeRefService()
      : undefined;

    if (typeof window !== 'undefined') {
      const searchProposalsUrl =
        window.SETUPS?.get('nemo.url.searchproposals') || '';

      if (searchProposalsUrl !== '') {
        searchManagerService.setSuggestedAndProposalSearchUrl(
          searchProposalsUrl,
        );
      }

      searchManagerService.events.on(
        SearchEventType.DEBUG_MODE_TOGGLED,
        (debugState: boolean) => {
          if (
            typeof window !== 'undefined' &&
            typeof window['core-global'] !== 'undefined'
          ) {
            window['core-global'].state.triggerModeChange('debug', debugState);
          }
          headerStateService.setDebugMode(debugState);
        },
      );
    }

    let headerConfig: HeaderResponse = initialHeaderConfig;

    // We use the presence of the asyncSsrManager to determine whether we are
    // rendered on the server or on the client.
    // note: fetch for client must be done within the component,
    // because we do not have a (React) state yet so Component will never know when to rerender
    if (asyncSsrManager && headerConfigDataUrl) {
      if (typeof serializedStateManager !== 'undefined') {
        serializedStateManager.register(() => JSON.stringify(headerConfig));
      }
      asyncSsrManager.scheduleRerender(
        (async (): Promise<HeaderResponse> => {
          headerConfig = await fetchHeaderConfig(headerConfigDataUrl);
          return headerConfig;
        })(),
      );
    } else {
      const serializedAudiHeaderData = serializedStateManager?.getSerializedState();
      if (serializedAudiHeaderData) {
        headerConfig = JSON.parse(serializedAudiHeaderData);
      }
    }

    return {
      // disable eslint rule because this is a (Feature Hub) React Feature App that doesn't define a `displayName` attribute
      // eslint-disable-next-line react/display-name
      render: (): React.ReactNode => (
        <AudiPlatformProvider theme={audiLightTheme}>
          <AudiHeaderDataManager
            audiMarketContextService={audiMarketContextService}
            featureAppID={featureAppId}
            headerConfigDataUrl={headerConfigDataUrl}
            headerStateService={headerStateService}
            layerManager={layerManager}
            loginFeatureAppUrl={loginFeatureAppUrl}
            miniCartFeatureAppBaseUrl={miniCartFeatureAppBaseUrl}
            miniCartFeatureAppSrc={miniCartFeatureAppSrc}
            referenceServiceManager={referenceServiceManager}
            searchInputFeatureAppUrl={searchInputFeatureAppUrl}
            searchManagerService={searchManagerService}
            searchResultsFeatureAppUrl={searchResultsFeatureAppUrl}
            ssrHeaderConfig={headerConfig}
          />
        </AudiPlatformProvider>
      ),
    };
  },

  dependencies: {
    externals: {
      '@feature-hub/react': '^2.7.0',
      react: '^16.13.0',
    },
    featureServices: {
      'audi-header-state-service': '1.0.0',
      'layer-manager': '2.4.0',
      'locale-service': '1.1.0',
      'search-manager-service': '3.0.0',
    },
  },

  optionalDependencies: {
    featureServices: {
      'audi-footnote-reference-service': '3.0.0',
      'audi-market-context-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [defineSearchManagerService()],
};

export default featureAppDefinition;
