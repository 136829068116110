import React, { useEffect, useState } from 'react';

import AudiHeader from './AudiHeader';
import { AudiHeaderDataManagerProps } from '../../interfaces/header-components.interfaces';
import { EnumerableFootnote } from '@volkswagen-onehub/audi-footnote-reference-service/dist/store/v2/footnote-reference-store';
import HeaderResponse from '../../interfaces/header-response.interfaces';
import { INavigationSearchResponse } from '@volkswagen-onehub/audi-search-manager-service/dist/interfaces';
import { createUseFootnoteReferenceServiceTextParserHook } from '../../services/hooks';
import { fetchHeaderConfig } from '../../services/navigation-service';

const AudiHeaderDataManager: React.FC<AudiHeaderDataManagerProps> = ({
  audiMarketContextService,
  ssrHeaderConfig,
  headerConfigDataUrl,
  loginFeatureAppUrl,
  miniCartFeatureAppBaseUrl,
  miniCartFeatureAppSrc,
  searchInputFeatureAppUrl,
  searchResultsFeatureAppUrl,
  headerStateService,
  featureAppID,
  referenceServiceManager,
  searchManagerService,
  layerManager,
}) => {
  const [footnotes, setFootnotes] = useState([]);
  const wrapperElement = React.createRef<HTMLDivElement>();
  const [headerConfig, setHeaderConfig] = useState<HeaderResponse>(
    ssrHeaderConfig,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const useFootnoteReferenceServiceTextParserHook = createUseFootnoteReferenceServiceTextParserHook(
    {
      footnotes,
      referenceServiceManager,
    },
  );

  useEffect(() => {
    if (window && window.location.href.indexOf('debug=true') !== -1) {
      headerStateService.setDebugMode(true);
    }
    return (): void => {
      if (window) {
        if (window.location.href.indexOf('debug=true') !== -1) {
          headerStateService.setDebugMode(true);
        } else {
          headerStateService.setDebugMode(false);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referenceServiceManager) {
      referenceServiceManager.registerCallback(
        (_footnotes: EnumerableFootnote[]) => {
          setFootnotes(_footnotes);
        },
      );
    }
    return (): void => {
      if (referenceServiceManager) {
        referenceServiceManager.removeFootnoteReferences();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // always load clientside again, to be sure, not to work with a cached version from SSR
    fetchHeaderConfig(headerConfigDataUrl).then((fetchedHeaderConfigData) => {
      setHeaderConfig(fetchedHeaderConfigData);
      setIsLoading(false);
    });
  }, [headerConfigDataUrl, ssrHeaderConfig]);

  useEffect(() => {
    if (searchManagerService && window) {
      const {
        setVTPAndDealerSearchConfig,
        setDealerSearchRedirectUrl,
        setClientIdParam,
        setNavigationSearchData,
      } = searchManagerService;

      if (headerConfig?.Search?.OneHeaderSearchClientId && setClientIdParam) {
        setClientIdParam(headerConfig.Search.OneHeaderSearchClientId);
      }
      if (headerConfig?.MainNavigation && setNavigationSearchData) {
        setNavigationSearchData({
          MainNavigation: headerConfig.MainNavigation,
        } as INavigationSearchResponse);
      }

      const config = {
        dealerSearch: !!headerConfig?.Search?.ShowDealerSearchLink,
        newStockCarSearch: !!headerConfig?.Search?.ShowNewCarsLink,
        usedAndPreOwnedStockCarSearch: !!headerConfig?.Search?.ShowUsedCarsLink,
      };

      setVTPAndDealerSearchConfig(config);

      if (
        headerConfig?.Search?.DealerSearchLink &&
        setDealerSearchRedirectUrl
      ) {
        setDealerSearchRedirectUrl(headerConfig.Search.DealerSearchLink);
      }
    }
  }, [headerConfig, searchManagerService]);

  return (
    <AudiHeader
      audiMarketContextService={audiMarketContextService}
      featureAppID={featureAppID}
      headerConfig={headerConfig}
      headerStateService={headerStateService}
      isLoading={isLoading}
      layerManager={layerManager}
      loginFeatureAppUrl={loginFeatureAppUrl}
      miniCartFeatureAppBaseUrl={miniCartFeatureAppBaseUrl}
      miniCartFeatureAppSrc={miniCartFeatureAppSrc}
      searchInputFeatureAppUrl={searchInputFeatureAppUrl}
      searchResultsFeatureAppUrl={searchResultsFeatureAppUrl}
      useFootnoteReferenceServiceTextParserHook={
        useFootnoteReferenceServiceTextParserHook
      }
      wrapperElement={wrapperElement}
    />
  );
};

export default AudiHeaderDataManager;
