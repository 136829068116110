import { ActiveItem } from '../reducers/header-reducer';
import { AudiHeaderStateServiceInterfaceV1 } from '@volkswagen-onehub/audi-header-state-service';

enum HeaderActionTypes {
  SetActiveItem = 'SET_ACTIVE_ITEM',
}

interface ActiveItemAction {
  type: HeaderActionTypes;
  payload: {
    activeItem: ActiveItem;
  };
}

export type IHeaderAction = ActiveItemAction;

export const createSetActiveItemAction: ({
  anchor,
  index,
  showSearch,
  headerStateService,
}: {
  anchor: HTMLAnchorElement;
  index: number;
  showSearch: boolean;
  headerStateService: AudiHeaderStateServiceInterfaceV1;
}) => ActiveItemAction = ({
  anchor,
  index,
  showSearch,
  headerStateService,
}) => {
  headerStateService.setShowSearch(showSearch);
  if (!showSearch && index !== -1) {
    headerStateService.setShowLoginFlyout(false);
  }

  return {
    payload: {
      activeItem: {
        anchor,
        index,
        showSearch,
      },
    },
    type: HeaderActionTypes.SetActiveItem,
  };
};

export const createResetActiveItemAction: (
  headerStateService: AudiHeaderStateServiceInterfaceV1,
) => ActiveItemAction = (headerStateService) => {
  return createSetActiveItemAction({
    anchor: null,
    headerStateService,
    index: -1,
    showSearch: false,
  });
};
