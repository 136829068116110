import * as React from 'react';
import { timeL, timeS } from '../AudiHeader/AudiHeaderStyleUtils';
import AppLoaderErrorBoundary from '../AppLoaderErrorBoundary/AppLoaderErrorBoundary';
import { AudiHeaderSearchProps } from '../../interfaces/header-components.interfaces';
import { FeatureAppLoader } from '@feature-hub/react';
import { HeaderSearchStyledProps } from '../../interfaces/header-styled-components.interfaces';
import { createResetActiveItemAction } from '../../actions/header-actions';
import styled from 'styled-components';

const HeaderSearchControls = styled.div<HeaderSearchStyledProps>`
  position: absolute;
  top: 0;
  width: 100%;

  & > legend {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }

  & > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    left: var(--one-header-side-spacing);
    opacity: ${({ isSearchOpen }): string => (isSearchOpen ? '1' : '0')};
    padding: 0;
    position: absolute;
    top: var(--one-header-space-l);
    transition: opacity ${timeS};

    & > svg {
      height: 24px;
      transform: scaleX(-1);
      width: 24px;
    }
  }
`;

const HeaderSearchFeatureApp = styled.div<HeaderSearchStyledProps>`
  background-color: var(--one-header-color-white);
  margin: 0;
  opacity: ${({ isSearchOpen }): string => (isSearchOpen ? '1' : '0')};
  position: relative;
  transition: opacity ${timeS};
  width: 100%;
`;

const HeaderSearch = styled.div<HeaderSearchStyledProps>`
  background-color: var(--one-header-color-white);
  display: block;
  height: 71px;
  left: 0;
  opacity: ${({ isSearchOpen }): string => (isSearchOpen ? '1' : '0')};
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity ${({ theme }): string => theme.easing} ${timeL};
  visibility: ${({ isSearchOpen }): string =>
    isSearchOpen ? 'visible' : 'hidden'};
  width: 100%;
  z-index: 5;
`;

const AudiHeaderSearch: React.FC<AudiHeaderSearchProps> = (props) => {
  const {
    isSearchOpen,
    search,
    headerDispatch,
    searchInputFeatureAppUrl,
    searchResultsFeatureAppUrl,
    headerStateService,
  } = props;

  const handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void = (
    event,
  ) => {
    event.preventDefault();

    headerDispatch(createResetActiveItemAction(headerStateService));
  };

  return (
    <React.Fragment>
      <HeaderSearch isSearchOpen={isSearchOpen}>
        <HeaderSearchFeatureApp isSearchOpen={isSearchOpen}>
          <div>
            <AppLoaderErrorBoundary>
              <FeatureAppLoader
                featureAppId="header-search-input"
                src={searchInputFeatureAppUrl}
              ></FeatureAppLoader>
            </AppLoaderErrorBoundary>
          </div>
          <div>
            <AppLoaderErrorBoundary>
              <FeatureAppLoader
                featureAppId="header-search-results"
                src={searchResultsFeatureAppUrl}
              ></FeatureAppLoader>
            </AppLoaderErrorBoundary>
          </div>
        </HeaderSearchFeatureApp>
        <HeaderSearchControls isSearchOpen={isSearchOpen}>
          <legend>{search.Legend}</legend>
          <button onClick={handleClick} type="button">
            <svg
              height="48"
              viewBox="0 0 24 24"
              width="48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 16.9L15.5 11.5 10 6.1"
                fill="none"
                stroke="currentColor"
              />
            </svg>
          </button>
        </HeaderSearchControls>
      </HeaderSearch>
    </React.Fragment>
  );
};
export default AudiHeaderSearch;
