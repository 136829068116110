import * as React from 'react';
import { IHeaderAction } from '../actions/header-actions';

export interface ActiveItem {
  anchor: HTMLAnchorElement;
  index: number;
  showSearch: boolean;
}

export interface HeaderState {
  activeItem: ActiveItem;
}

export const headerReducer: React.Reducer<HeaderState, IHeaderAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'SET_ACTIVE_ITEM':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
