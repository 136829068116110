import '../../styles/bodyClasses.css';

import * as React from 'react';
import { HeaderState, headerReducer } from '../../reducers/header-reducer';
import {
  HeaderStyled,
  HeaderStyledShader,
  HeaderStyledUserActionWrapper,
  HeaderStyledWrapper,
} from './AudiHeaderStyles';
import {
  IHeaderAction,
  createResetActiveItemAction,
  createSetActiveItemAction,
} from '../../actions/header-actions';
import {
  useClickOnElementByClassName,
  useStateSwitchTimeOutHook,
} from '../../services/hooks';
import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import AudiHeaderLogin from '../AudiHeaderLogin/AudiHeaderLogin';
import AudiHeaderLogo from '../AudiHeaderLogo/AudiHeaderLogo';
import AudiHeaderMenuButton from '../AudiHeaderMenuButton/AudiHeaderMenuButton';
import AudiHeaderMiniCart from '../AudiHeaderMiniCart/AudiHeaderMiniCart';
import AudiHeaderNav from '../AudiHeaderNav/AudiHeaderNav';
import { AudiHeaderProps } from '../../interfaces/header-components.interfaces';
import AudiHeaderSearch from '../AudiHeaderSearch/AudiHeaderSearch';
import AudiHeaderSearchButton from '../AudiHeaderSearchButton/AudiHeaderSearchButton';
import { ThemeContext } from 'styled-components';

// eslint-disable-next-line max-statements
const AudiHeader: React.FC<AudiHeaderProps> = (props) => {
  const {
    audiMarketContextService,
    actionWrapperElement,
    headerConfig,
    headerStateService,
    isLoading,
    layerManager,
    loginFeatureAppUrl,
    miniCartFeatureAppBaseUrl,
    miniCartFeatureAppSrc,
    navigationElement,
    searchInputFeatureAppUrl,
    searchResultsFeatureAppUrl,
    wrapperElement,
    featureAppID,
    useFootnoteReferenceServiceTextParserHook,
  } = props;

  const themeContext = useContext(ThemeContext);

  const headerNavigationItemsAmount = useMemo(
    () => headerConfig?.MainNavigation?.length || 0,
    [headerConfig?.MainNavigation?.length],
  );

  const [headerState, headerDispatch] = useReducer<
    React.Reducer<HeaderState, IHeaderAction>
  >(headerReducer, {
    activeItem: {
      anchor: null,
      index: -1,
      showSearch: false,
    },
  });

  const showShader = useMemo(
    () =>
      headerState.activeItem.index !== -1 || headerState.activeItem.showSearch,
    [headerState.activeItem],
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [disableTransition, setDisableTransition] = useState<boolean>(false);

  const {
    state: isClosing,
    stateSwitch: addClosingAnimation,
  } = useStateSwitchTimeOutHook(440);

  const [windowWidth, setWindowWith] = useState(
    (typeof window !== 'undefined' && window.innerWidth) || 0,
  );

  useEffect(() => {
    const resizeHandler = (): void => {
      setWindowWith((typeof window !== 'undefined' && window.innerWidth) || 0);

      let timer: ReturnType<typeof setTimeout>;
      if (timer) {
        clearTimeout(timer);
        timer = null;
      } else {
        setDisableTransition(true);
      }

      timer = setTimeout(() => {
        setDisableTransition(false);
        timer = null;
      }, 200);
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeHandler);
    }
    return (): void => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', resizeHandler);
      }
    };
  }, []);

  const closeMenu: () => void = () => {
    const layers = layerManager?.getLayers() || [];
    if (layers.length > 0) {
      return;
    }

    headerDispatch(createResetActiveItemAction(headerStateService));
    addClosingAnimation();
    setIsOpen(false);
  };

  useClickOnElementByClassName({
    className: 'audi-j-footnote-reference',
    clickCallBack: closeMenu,
  });

  useEffect(() => {
    const expandedClass = 'u-header-expanded';
    if (isOpen || headerState.activeItem.showSearch) {
      document.body.classList.add(expandedClass);
    } else {
      document.body.classList.remove(expandedClass);
    }
  }, [isOpen, headerState.activeItem.showSearch]);

  useEffect(() => {
    if (isLoading) {
      setDisableTransition(true);
    } else {
      setTimeout(() => {
        setDisableTransition(false);
      }, 660);
    }
  }, [isLoading]);

  useEffect(() => {
    headerStateService.registerCallback((headerStateRemote) => {
      if (headerStateRemote.showLoginFlyout === true) {
        headerDispatch(
          createSetActiveItemAction({
            anchor: null,
            headerStateService,
            index: -1,
            showSearch: false,
          }),
        );
        setIsOpen(false);
      }
    });
  }, [headerStateService]);

  const onClickMenuButton: () => void = () => {
    if (!isOpen) {
      setIsOpen(!isOpen);
      headerDispatch(createResetActiveItemAction(headerStateService));
      headerStateService.setShowLoginFlyout(false);
    } else {
      closeMenu();
    }
  };

  const isSmallView =
      (windowWidth <= themeContext.breakpoints.xxl &&
        headerNavigationItemsAmount >= 8) ||
      (windowWidth <= themeContext.breakpoints.xl &&
        headerNavigationItemsAmount >= 5) ||
      (windowWidth <= themeContext.breakpoints.l &&
        headerNavigationItemsAmount >= 4),
    legacyClassNames = isSmallView
      ? 'header header--loaded header--view-small'
      : 'header header--loaded';

  useEffect(() => {
    headerStateService.setisMobileView(isSmallView);
  }, [isSmallView, headerStateService]);

  return (
    <>
      <HeaderStyled
        className={legacyClassNames}
        data-fefa-custom-id={featureAppID}
        data-module={'one-header'}
        data-navigation-items-amount={headerNavigationItemsAmount}
        disableTransition={disableTransition}
        ref={wrapperElement}
      >
        {showShader && (
          <HeaderStyledShader onClick={closeMenu} showShader={showShader} />
        )}
        <HeaderStyledWrapper
          headerNavigationItemsAmount={headerNavigationItemsAmount}
        >
          <AudiHeaderLogo
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            isLoading={isLoading}
            isOpen={isOpen}
            logoLink={headerConfig.Logo}
          />
          <AudiHeaderMenuButton
            headerNavigationItemsAmount={headerNavigationItemsAmount}
            isLoading={isLoading}
            isOpen={isOpen}
            label={headerConfig.MenuLabel}
            onClick={onClickMenuButton}
          />
          <React.Fragment>
            <AudiHeaderNav
              audiMarketContextService={audiMarketContextService}
              headerDispatch={headerDispatch}
              headerNavigationItemsAmount={headerNavigationItemsAmount}
              headerState={headerState}
              headerStateService={headerStateService}
              isClosing={isClosing}
              isOpen={isOpen}
              layerManager={layerManager}
              navigation={headerConfig.MainNavigation}
              navigationElement={navigationElement}
              useFootnoteReferenceServiceTextParserHook={
                useFootnoteReferenceServiceTextParserHook
              }
              windowWidth={windowWidth}
            />
            <HeaderStyledUserActionWrapper
              isLoading={isLoading}
              ref={actionWrapperElement}
            >
              {headerConfig.Search &&
                searchInputFeatureAppUrl &&
                searchResultsFeatureAppUrl && (
                  <AudiHeaderSearchButton
                    headerDispatch={headerDispatch}
                    headerNavigationItemsAmount={headerNavigationItemsAmount}
                    headerStateService={headerStateService}
                    isOpen={isOpen}
                    search={headerConfig.Search}
                  />
                )}
              {
                /* istanbul ignore next because the following component just renders a feature app that is not ours */
                headerConfig.OneShopEnabled && (
                  <AudiHeaderMiniCart
                    headerNavigationItemsAmount={headerNavigationItemsAmount}
                    isOpen={isOpen}
                    miniCartFeatureAppBaseUrl={miniCartFeatureAppBaseUrl}
                    miniCartFeatureAppSrc={miniCartFeatureAppSrc}
                  />
                )
              }
              {headerConfig.Login && loginFeatureAppUrl && (
                <AudiHeaderLogin
                  headerNavigationItemsAmount={headerNavigationItemsAmount}
                  isOpen={isOpen}
                  loginFeatureAppUrl={loginFeatureAppUrl}
                />
              )}
            </HeaderStyledUserActionWrapper>
            {headerConfig.Search &&
              searchInputFeatureAppUrl &&
              searchResultsFeatureAppUrl && (
                <AudiHeaderSearch
                  headerDispatch={headerDispatch}
                  headerStateService={headerStateService}
                  isSearchOpen={headerState.activeItem.showSearch}
                  search={headerConfig.Search}
                  searchInputFeatureAppUrl={searchInputFeatureAppUrl}
                  searchResultsFeatureAppUrl={searchResultsFeatureAppUrl}
                />
              )}
          </React.Fragment>
        </HeaderStyledWrapper>
      </HeaderStyled>
      {/* remove after AEM template adaption (extract audi-header from nm-wrapper) */}
      <div className={'u-header-nemo-fullwidth-patch'} />
    </>
  );
};

export default AudiHeader;
